// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-for-sale-tsx": () => import("./../src/pages/for-sale.tsx" /* webpackChunkName: "component---src-pages-for-sale-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-manpage-tsx": () => import("./../src/templates/manpage.tsx" /* webpackChunkName: "component---src-templates-manpage-tsx" */),
  "component---src-templates-page-tsx": () => import("./../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-post-tsx": () => import("./../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-sale-tsx": () => import("./../src/templates/sale.tsx" /* webpackChunkName: "component---src-templates-sale-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../src/templates/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */)
}

